(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-race.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-race.js');
"use strict";

const _excluded = ["draws"],
  _excluded2 = ["raceSummaries"],
  _excluded3 = ["draws", "meeting"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  raceNormalizer
} = svs.components.marketplaceData.draws.helpers;
const {
  normalizeRacecard,
  normalizeRace
} = svs.racing.raceService.utils.normalize;
const defaultOutComes = [{
  description: '1:a',
  outcomeNumber: 1
}, {
  description: '2:a',
  outcomeNumber: 2
}, {
  description: '3:a',
  outcomeNumber: 3
}, {
  description: '4:a',
  outcomeNumber: 4
}, {
  description: '5:a',
  outcomeNumber: 5
}];
const normalizeRaceMarketplace = res => {
  const outcomes = defaultOutComes;
  if (res.raceCard) {
    const normalisedRaceCard = normalizeRacecard(res);
    const drawsToReturn = [];
    const races = normalisedRaceCard.map(meeting => meeting.raceSummaries.map(raceSummary => {
      const {
          draws
        } = raceSummary,
        restOfSummary = _objectWithoutProperties(raceSummary, _excluded);
      drawsToReturn.push(...raceNormalizer(_objectSpread({
        draws
      }, restOfSummary)));
      return _objectSpread(_objectSpread({}, restOfSummary), {}, {
        meetingId: meeting.id
      });
    })).flat(1);
    const racesById = races.reduce((races, race) => _objectSpread(_objectSpread({}, races), {}, {
      [race.raceId]: _objectSpread({}, race)
    }), {});
    const meetingsById = normalisedRaceCard.map(_ref => {
      let {
          raceSummaries
        } = _ref,
        restOfMeeting = _objectWithoutProperties(_ref, _excluded2);
      return restOfMeeting;
    }).reduce((meetings, meeting) => _objectSpread(_objectSpread({}, meetings), {}, {
      [meeting.id]: _objectSpread({}, meeting)
    }), {});
    return {
      draws: drawsToReturn,
      meetings: meetingsById,
      races: racesById
    };
  } else if (res.race) {
    var _restRace$participant;
    const _normalizeRace = _objectSpread({}, normalizeRace(res.race)),
      {
        draws,
        meeting
      } = _normalizeRace,
      restRace = _objectWithoutProperties(_normalizeRace, _excluded3);
    return {
      draws: raceNormalizer(_objectSpread({
        draws
      }, restRace)),
      meetings: {
        [meeting.id]: _objectSpread({}, meeting)
      },
      races: {
        [res.race.raceId]: _objectSpread(_objectSpread({}, restRace), {}, {
          meetingId: meeting.id,
          participants: restRace === null || restRace === void 0 || (_restRace$participant = restRace.participants) === null || _restRace$participant === void 0 ? void 0 : _restRace$participant.map(participant => _objectSpread(_objectSpread({}, participant), {}, {
            outcomes
          }))
        })
      }
    };
  }
  throw new Error('Not supported!');
};
setGlobal('svs.components.marketplaceData.draws.helpers.normalizeRaceMarketplace', normalizeRaceMarketplace);

 })(window);